<a *ngIf="connectionMeta.link; else noLink" [href]="connectionMeta.link" target="_blank" class="social-link-container">
  <p>{{ (connectionMeta.header | translate) || connection.platform }}</p>
  <fa-icon [icon]="connectionMeta.icon" size="2x"></fa-icon>
  <p>{{ usernameFormatted }}</p>
</a>

<ng-template #noLink>
  <div class="social-link-container">
    <p>{{ (connectionMeta.header | translate) || connection.platform }}</p>
    <fa-icon [icon]="connectionMeta.icon" size="2x"></fa-icon>
    <p>{{ usernameFormatted }}</p>
  </div>
</ng-template>
