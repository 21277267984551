<!-- TODO: find out why the enter key changes password visibility -->

<label class="label">{{ 'user.settings.password.label' | translate }}</label>
<div class="field has-addons">
  <div class="control has-icons-left is-expanded">
    <input class="input"
           type="password"
           name="password"
           placeholder="********"
           *ngIf="passwordHidden; else plainTextPw"
           [ngModel]="password"
           (ngModelChange)="passwordChange.emit($event)" />
    <ng-template #plainTextPw>
      <input class="input"
             type="text"
             name="password"
             placeholder="password"
             [ngModel]="password"
             (ngModelChange)="passwordChange.emit($event)" />
    </ng-template>
    <span class="icon is-small is-left">
      <fa-icon [icon]="iconPadlock"></fa-icon>
    </span>
  </div>

  <div class="control">
    <button class="button" type="button" (click)="passwordHidden = !passwordHidden"
            [title]="(passwordHidden ? 'login.password.show' : 'login.password.hide') | translate">
              <span class="icon cursor-pointer">
                <fa-icon [icon]="iconEye" *ngIf="passwordHidden; else eyeSlash"></fa-icon>
                <ng-template #eyeSlash>
                  <fa-icon [icon]="iconEyeSlash"></fa-icon>
                </ng-template>
              </span>
    </button>
  </div>
</div>
