<label for="languages" class="label">{{'user.settings.language.label' | translate}}</label>
<div class="control">
  <input id="languages"
         class="input"
         type="text"
         name="languages"
         [value]="languages"
         ngModel
         #languagesEl
  />
</div>
