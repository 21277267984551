<article>
  <h3 class="title is-3">
    {{ 'aboutPage.about.title' | translate }}
  </h3>
  <p>
    {{ 'aboutPage.about.par1' | translate }}
  </p>
  <p [innerHTML]="'aboutPage.about.par2' | translate"></p>
  <p [innerHTML]="'aboutPage.about.par3' | translate"></p>
  <br/>
  <p [innerHTML]="'aboutPage.about.par4' | translate"></p>
  <p [innerHTML]="'aboutPage.about.par5' | translate"></p>
  <br/>
  <p>
    {{ 'aboutPage.about.par6' | translate }}
  </p>
</article>
