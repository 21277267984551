<div class="notification is-info mb-4">
  <p>{{ 'alert.submission.cache' | translate }}</p>
</div>

<h3 class="title is-3 mb-4">
  {{ 'marathon.submissions.title' | translate }}
  <button class="button is-pulled-right is-info" (click)="exportToCsv()"
          *ngIf="marathonService.isAdmin(userService.user)">{{ 'marathon.submissions.export' | translate}}
  </button>
</h3>

<div class="tabs is-centered" *ngIf="displaysTabs">
  <ul>
    <li [ngClass]="{'is-active':active==='submissions'}">
      <a (click)="active = 'submissions'">{{'marathon.submissions.tabs.submissions' | translate}}</a>
    </li>
    <li [ngClass]="{'is-active':active==='answers'}">
      <a (click)="active = 'answers'">{{'marathon.submissions.tabs.answers' | translate}}</a>
    </li>
  </ul>
</div>

<div [hidden]="displaysTabs && active !== 'submissions'" class="container" id="submissionScrollArea">

  <div class="columns">
    <div class="column is-half-desktop">
      <nav class="panel">
        <div class="panel-block">
          <p class="control has-icons-left">
            <input class="input searchInput" type="text" #searchInput
                   [(ngModel)]="gameFilter"
                   [placeholder]="'marathon.submissions.filter' | translate"
                   (input)="search(searchInput.value)">
            <span class="icon is-left">
              <fa-icon [icon]="faSearch"></fa-icon>
            </span>
          </p>
        </div>
        <div class="panel-block" *ngIf="marathonService.marathon.selectionDone">
          <div class="select is-fullwidth">
            <select [(ngModel)]="statusFilter" (ngModelChange)="search(searchInput.value)">
              <option value="">{{ 'marathon.selection.status.ALL' | translate}}</option>
              <option value="REJECTED">{{ 'marathon.selection.status.REJECTED' | translate}}</option>
              <option value="BONUS">{{ 'marathon.selection.status.BONUS' | translate}}</option>
              <option value="BACKUP">{{ 'marathon.selection.status.BACKUP' | translate}}</option>
              <option value="VALIDATED">{{ 'marathon.selection.status.VALIDATED' | translate}}</option>
            </select>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div class="is-centered" *ngIf="loading">
    <app-loading-indicator></app-loading-indicator>
  </div>

  <ng-template ngFor let-submission [ngForOf]="filteredSubmissions$ | async">
    <div class="box">
      <h4 class="title is-4">
        <app-user-link [user]="submission.user" [isLink]="true" [showAvatar]="true"></app-user-link>
        <app-delete-button *ngIf="showDelete"
                           [baseText]="'marathon.submissions.submission.delete' | translate"
                           [confirmText]="'action.confirm' | translate"
                           [cancelText]="'action.cancel' | translate"
                           (confirmHandler)="deleteSubmission(submission.id)"
        ></app-delete-button>
      </h4>
      <ng-template ngFor let-game [ngForOf]="submission.games">
        <app-submission-game
          [game]="game"
          [marathon]="marathonService.marathon"
          [showDelete]="showDelete"
          [selection]="selection"
          [userIsAdmin]="userIsAdmin"
          (deleteGame)="deleteGame(game.id)"
          (deleteCategory)="deleteCategory(game.id, $event)"
        ></app-submission-game>
      </ng-template>
    </div>
  </ng-template>
  <div id="lazyLoadTrigger" class="is-centered" *ngIf="canLoadMore" [hidden]="gameFilter || statusFilter">
    <app-loading-indicator></app-loading-indicator>
  </div>
</div>

<div [hidden]="!displaysTabs || active !== 'answers'" class="container">
  <div class="table-container">
    <table class="table is-fullwidth is-striped">
      <thead>
      <tr>
        <th>{{ 'marathon.submissions.table.runner' | translate}}</th>
        <th>{{ 'marathon.submissions.table.question' | translate}}</th>
        <th>{{ 'marathon.submissions.table.answer' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <ng-template ngFor let-entry [ngForOf]="answers | keyvalue">
        <tr *ngFor="let answer of entry.value; let i = index">
          <ng-template [ngIf]="i === 0">
            <td [attr.rowspan]="entry.value.length">
              <app-user-link [username]="entry.key" [isLink]="true" [showAvatar]="true"></app-user-link>
            </td>
          </ng-template>
          <td>
            {{ questions.get(answer.questionId)?.label ?? 'MissingNo' }}
          </td>
          <td>
            <p class="content">
              <app-oengus-md [data]="answer.answer"></app-oengus-md>
            </p>
          </td>
        </tr>
      </ng-template>
      </tbody>
    </table>
  </div>
</div>
