<div class="container" *ngIf="showNextStep">
  <h1 class="title">{{ 'register.thank_you' | translate }}</h1>
  <h2 class="subtitle">{{ 'register.email_sent' | translate }}</h2>
  <p>
    <app-element-i18n key="register.login_hint" elementKey="login-link">
      <a [routerLink]="'/login'  | localize">{{ 'action.login' | translate }}</a>
    </app-element-i18n>
  </p>
</div>

<!-- TODO: multi step system to not overwhelm users -->
<div class="container" *ngIf="!showNextStep">
  <h1 class="title">{{'user.settings.new.beforeWeStart' | translate}}</h1>
  <h2 class="subtitle">{{'user.settings.new.description' | translate}}</h2>

  <form *ngIf="!showNextStep" ngForm="newUserForm" (ngSubmit)="submit()" #form="ngForm">
    <!-- Display name -->
    <div class="field">
      <label for="displayName" class="label">{{'user.settings.displayName.label' | translate}}</label>
      <div class="control has-icons-left">
        <input class="input"
               [ngClass]="{'is-danger': displayName.invalid || errors.displayName}"
               type="text"
               [(ngModel)]="data.displayName"
               name="displayName"
               id="displayName"
               #displayName="ngModel"
               minlength="1"
               maxlength="32"
               required
        />

        <span class="icon is-small is-left">
          <fa-icon [icon]="iconUser"></fa-icon>
        </span>
      </div>
      <p class="help is-danger"
         *ngIf="errors.displayName">{{ errors.displayName }}</p>
      <div *ngIf="displayName.invalid">
        <p class="help is-danger"
           *ngIf="displayName.errors.maxlength">{{'user.settings.displayName.error.max' | translate}}</p>
        <p class="help is-danger"
           *ngIf="displayName.errors.minlength">{{'user.settings.displayName.error.min' | translate}}</p>
        <p class="help is-danger"
           *ngIf="displayName.errors.required">{{'user.settings.displayName.error.required' | translate}}</p>
      </div>
      <p class="help">{{'user.settings.displayName.help' | translate}}</p>
    </div>

    <!-- Username -->
    <div class="field">
      <label for="username" class="label">{{'user.settings.username.label' | translate}}</label>
      <div class="control has-icons-left">
        <input
          type="text"
          [ngClass]="{'is-danger': username.invalid || errors.username}"
          id="username"
          name="username"
          class="input"
          [(ngModel)]="data.username"
          #username="ngModel"
          minlength="3"
          maxlength="32"
          pattern="^[\w\-]{3,32}$"
          appUsernameExistsValidator
          required
        />
        <span class="icon is-small is-left">
          <fa-icon [icon]="iconUser"></fa-icon>
        </span>
      </div>
      <p class="help is-danger"
         *ngIf="errors.username">{{ errors.username }}</p>
      <div *ngIf="username.invalid">
        <p class="help is-danger"
           *ngIf="username.errors.minlength">{{'user.settings.username.error.min' | translate}}</p>
        <p class="help is-danger"
           *ngIf="username.errors.maxlength">{{'user.settings.username.error.max' | translate}}</p>
        <p class="help is-danger"
           *ngIf="username.errors.exists">{{'user.settings.username.error.exists' | translate}}</p>
        <p class="help is-danger"
           *ngIf="username.errors.required">{{'user.settings.username.error.required' | translate}}</p>
        <p class="help is-danger"
           *ngIf="username.errors.pattern">{{'user.settings.username.error.pattern' | translate}}</p>
      </div>
    </div>

    <div class="field">
      <label class="label" for="pfp">{{ 'user.settings.profilePicture.label' | translate }}</label>
      <div class="control">
        <a href="https://gravatar.com/" target="_blank" class="button is-info" id="pfp">{{ 'user.settings.profilePicture.button' | translate }}</a>
      </div>
      <p class="help" [innerHTML]="'user.settings.email.help' | translate"></p>
    </div>

    <!-- Email -->
    <div class="field">
      <label for="email" class="label">{{'user.settings.email.label' | translate}}</label>
      <div class="control has-icons-left">
        <input class="input"
               [ngClass]="{'is-danger': mail.invalid || errors.email}"
               type="email" [(ngModel)]="data.email"
               id="email"
               name="mail"
               #mail="ngModel"
               email
               required
        />
        <p class="help" [innerHTML]="'user.settings.email.help' | translate"></p>
        <span class="icon is-small is-left">
          <fa-icon [icon]="iconEmail"></fa-icon>
        </span>
      </div>
      <p class="help is-danger"
         *ngIf="errors.email">{{ errors.email }}</p>
      <div *ngIf="mail.invalid">
        <p class="help is-danger" *ngIf="mail.errors.email">{{'user.settings.email.error.email' | translate}}</p>
        <p class="help is-danger" *ngIf="mail.errors.required">{{'user.settings.email.error.required' | translate}}</p>
      </div>
    </div>

    <!-- password -->
    <!-- TODO: make this a component -->
    <label class="label">{{ 'user.settings.password.label' | translate }}</label>
    <div class="field has-addons">
      <div class="control has-icons-left is-expanded">
        <input class="input"
               [ngClass]="{'is-danger': pwField.invalid || errors.password}"
               [type]="passwordHidden ? 'password' : 'text'"
               name="password"
               required
               [placeholder]="passwordHidden ? '********' : 'password'"
               [(ngModel)]="data.password"
               #pwField="ngModel"
        />
        <span class="icon is-small is-left">
          <fa-icon [icon]="iconPadlock"></fa-icon>
        </span>
      </div>

      <div class="control">
        <button class="button" type="button" (click)="passwordHidden = !passwordHidden"
                [title]="(passwordHidden ? 'login.password.show' : 'login.password.hide') | translate">
              <span class="icon cursor-pointer">
                <fa-icon [icon]="iconEye" *ngIf="passwordHidden; else eyeSlash"></fa-icon>
                <ng-template #eyeSlash>
                  <fa-icon [icon]="iconEyeSlash"></fa-icon>
                </ng-template>
              </span>
        </button>
    </div>
  </div>

    <div class="field"
         *ngIf="errors.password">
      <p class="help is-danger">{{ errors.password }}</p>
    </div>

  <div *ngIf="pwField.invalid" class="field">
    <p class="help is-danger"
       *ngIf="pwField.errors.required">{{'user.settings.password.error.required' | translate}}</p>
  </div>

    <!-- Country -->
    <app-element-country [(country)]="data.country"></app-element-country>

    <app-element-pronouns [(pronouns)]="data.pronouns"></app-element-pronouns>
    <app-element-languages [(languages)]="data.languagesSpoken"></app-element-languages>

    <!-- connections -->
    <app-element-connections class="field" [(connections)]="data.connections"></app-element-connections>

    <div class="field">
      <div class="control">
        <button class="button is-link"
                [ngClass]="{'is-loading': loading}"
                type="button"
                (click)="submit()"
                [disabled]="form.invalid">{{'register.submit' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
