<div class="is-centered">
  <a href="https://www.patreon.com/oengusio" target="_blank" class="patreon-brand-container">
    <img src="/assets/patreon/Digital-Patreon-Logo_FieryCoral.png" alt="Patreon Logo">
    <img src="/assets/patreon/Digital-Patreon-Wordmark_White.png" alt="Patreon Wordmark">
  </a>
</div>

<p>
  <app-element-i18n key="footer.patron.enjoyOengus" elementKey="patronLink">
    <a href="https://www.patreon.com/oengusio" target="_blank">
      {{ 'footer.patron.patronLink' | translate }}
    </a>
  </app-element-i18n>
</p>

<p>
  {{ 'footer.patron.list' | translate }}
</p>
<app-patron-list></app-patron-list>
