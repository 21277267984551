import { Component } from '@angular/core';

@Component({
  selector: 'app-about-contact',
  templateUrl: './about-contact.component.html',
  styleUrls: ['./about-contact.component.scss'],
  host: {
    class: 'box',
  },
})
export class AboutContactComponent {
}
