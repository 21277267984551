<div *ngIf="show" class="verify-host">
  <div>
    {{ 'navbar.email-verification.message' | translate }}
  </div>

  <div class="buttons">
    <button type="button"
            class="button is-info"
            [ngClass]="{'is-loading': loading}"
            [disabled]="!canPressButton"
            (click)="requestNewEmail()"
    >
      {{ 'navbar.email-verification.request-btn' | translate }}
    </button>
  </div>
</div>
