<article>
  <h3 class="title is-3">
    {{ 'aboutPage.privacy.title' | translate }}
  </h3>
  <p>
    <app-element-i18n key="aboutPage.privacy.par1" elementKey="oengus-link">
      <a href="https://oengus.io">https://oengus.io</a>
    </app-element-i18n>
  </p>
  <p>{{ 'aboutPage.privacy.par2'| translate }}</p>
  <p>{{ 'aboutPage.privacy.par3' | translate }}</p>
  <br>
  <h4 class="title is-4">
    {{ 'aboutPage.privacy.log.title' | translate }}
  </h4>
  <p>{{ 'aboutPage.privacy.log.par1' | translate }}</p>
  <br>
  <h4 class="title is-4">
    {{ 'aboutPage.privacy.cookies.title' | translate }}
  </h4>
  <p>{{ 'aboutPage.privacy.cookies.par1' | translate }}</p>
  <br>
  <h4 class="title is-4">
    {{ 'aboutPage.privacy.thirdParty.title' | translate }}
  </h4>
  <p>{{ 'aboutPage.privacy.thirdParty.par1' | translate }}</p>
  <p>{{ 'aboutPage.privacy.thirdParty.par2' | translate }}</p>
  <br>
  <h4 class="title is-4">
    {{ 'aboutPage.privacy.children.title' | translate }}
  </h4>
  <p>{{ 'aboutPage.privacy.children.par1' | translate }}</p>
  <p>{{ 'aboutPage.privacy.children.par2' | translate }}</p>
  <br>
  <h4 class="title is-4">
    {{ 'aboutPage.privacy.online.title' | translate }}
  </h4>
  <p>{{ 'aboutPage.privacy.online.par1' | translate }}</p>
  <br>
  <h4 class="title is-4">
    {{ 'aboutPage.privacy.consent.title' | translate }}
  </h4>
  <p>{{ 'aboutPage.privacy.consent.par1' | translate }}</p>
</article>
