<h1 class="title">
  {{ 'marathon.schedule.management.settings.createTitle' | translate }}
</h1>

<!-- TODO: add a back button?? -->

<form (ngSubmit)="submit()" #form="ngForm">
  <div class="field">
    <label for="sched_name" class="label">
      {{ 'marathon.schedule.management.settings.name.label' | translate }}
    </label>
    <div class="control">
      <input class="input"
             [ngClass]="{'is-danger': name.invalid}"
             [(ngModel)]="data.name"
             type="text"
             name="name"
             id="sched_name"
             placeholder="My Schedule"
             maxlength="50"
             minlength="1"
             pattern="^[a-zA-Z0-9_\- ]{1,50}$"
             required
             #name="ngModel"
      />
      <p class="help is-pulled-right">{{data.name?.length}}/50</p>
    </div>

    <div *ngIf="name.invalid">
      <p class="help is-danger" *ngIf="name.errors.required">
        {{ 'marathon.schedule.management.settings.name.error.required' | translate }}
      </p>
      <p class="help is-danger" *ngIf="name.errors.minlength">
        {{ 'marathon.schedule.management.settings.name.error.min' | translate }}
      </p>
      <p class="help is-danger" *ngIf="name.errors.maxlength">
        {{ 'marathon.schedule.management.settings.name.error.max' | translate }}
      </p>
      <p class="help is-danger" *ngIf="name.errors.pattern">
        {{ 'marathon.schedule.management.settings.name.error.pattern' | translate }}
      </p>
    </div>
  </div>

  <label class="label">
    {{ 'marathon.schedule.management.settings.slug.label' | translate }}
  </label>
  <div class="field">
    <div class="field has-addons is-marginless">
      <div class="control">
        <a class="button is-static">
          {{env.baseSite}}/marathon/{{ marathonId }}/schedule/
        </a>
      </div>
      <div class="control is-expanded">
        <input class="input"
               [ngClass]="{'is-danger': slug.invalid}"
               type="text"
               [(ngModel)]="data.slug"
               name="id"
               maxlength="255"
               minlength="1"
               pattern="^[\w\-]{1,255}$"
               required
               #slug="ngModel"
               appScheduleSlugExistsValidator
        />
      </div>
    </div>
    <p class="help is-pulled-right">{{data.slug?.length}}/10</p>
    <div *ngIf="slug.invalid">
      <p class="help is-danger" *ngIf="slug.errors.required">
        {{ 'marathon.schedule.management.settings.slug.error.required' | translate }}
      </p>
      <p class="help is-danger" *ngIf="slug.errors.minlength">
        {{ 'marathon.schedule.management.settings.slug.error.min' | translate }}
      </p>
      <p class="help is-danger" *ngIf="slug.errors.maxlength">
        {{ 'marathon.schedule.management.settings.slug.error.max' | translate }}
      </p>
      <p class="help is-danger" *ngIf="slug.errors.exists">
        {{ 'marathon.schedule.management.settings.slug.error.exists' | translate }}
      </p>
      <p class="help is-danger" *ngIf="slug.errors.pattern">
        {{ 'marathon.schedule.management.settings.slug.error.pattern' | translate }}
      </p>
    </div>
  </div>

  <div class="field">
    <div class="control">
      <input
        class="input"
        type="text"
        [value]="env.shortUrl + '/' + marathonId + '/' + data.slug"
        readonly
      />
    </div>
    <p class="help">{{ 'marathon.new.shortDomain' | translate}}</p>
  </div>

  <div class="field">
    <div class="control">
      <button class="button is-link"
              [ngClass]="{'is-loading': loading}"
              type="submit"
              [disabled]="form.invalid">
        {{'action.create' | translate}}
      </button>
    </div>
  </div>

</form>
