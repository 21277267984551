<p class="menu-label">
  {{ 'marathon.menu.moderators' | translate}}
</p>

<ul class="menu-list">
  <li *ngFor="let moderator of moderators">
    <a [routerLink]="'/user/' + moderator.username | localize" class="menu-item-link">
      <app-user-link [user]="moderator"></app-user-link>
    </a>
  </li>
</ul>
