<app-element-table-row>
  <app-element-table-cell [isExpandButton]="true" [id]="internalId" [expanded]="expanded" class="expandable"></app-element-table-cell>
  <app-element-table-cell [id]="'run-' + run.id" class="time">
    <app-element-temporal-datetime [dateTime]="run.date" format="shortTime"></app-element-temporal-datetime>
  </app-element-table-cell>

  <app-element-table-cell *ngIf="run.setupBlock; else notSetupBlock" class="setup-text" columnEnd="span 2">
    <app-simple-md [data]="(run.setupBlockText || 'marathon.schedule.setupBlock' | translate)" ></app-simple-md>
  </app-element-table-cell>
  <ng-template #notSetupBlock>
    <app-element-table-cell class="runners">
      <app-user-link *ngFor="let runner of run.runners" [user]="runner" [isLink]="runner?.profile?.id > 0"></app-user-link>
    </app-element-table-cell>
    <app-element-table-cell class="game">
      <app-simple-md [data]="run.game"></app-simple-md>
    </app-element-table-cell>
  </ng-template>

  <app-element-table-cell class="category">
    <app-simple-md [data]="run.category" ></app-simple-md>
  </app-element-table-cell>

  <app-element-table-cell class="type">
    {{ 'marathon.schedule.type.' + run.type | translate }}
  </app-element-table-cell>

  <app-element-table-cell class="console">
    <app-element-console [console]="run.console" [emulated]="run.emulated" ></app-element-console>
  </app-element-table-cell>

  <app-element-table-cell class="estimate">
    <app-element-temporal-duration [duration]="run.estimate"></app-element-temporal-duration>
  </app-element-table-cell>

  <app-element-table-cell class="setup">
    <app-element-temporal-duration [duration]="run.setupTime"></app-element-temporal-duration>
  </app-element-table-cell>
</app-element-table-row>
