<app-element-table class="moderation-table">
  <app-element-table-cell [isHeader]="true" class="marathon">
    {{ 'marathon.submissions.table.marathon' | translate }}
  </app-element-table-cell>
  <app-element-table-cell [isHeader]="true" class="date">
    {{ 'marathon.submissions.table.date' | translate }}
  </app-element-table-cell>

  <app-element-table-row *ngFor="let marathon of moderatedMarathons; let i = index" [class]="getRowParity(i)">
    <app-element-table-cell class="marathon">
      <a [routerLink]="'/marathon/' + marathon.marathonId | localize">{{ marathon.marathonName }}</a>
    </app-element-table-cell>
    <app-element-table-cell class="date">
      <span>
        <app-element-temporal-datetime [dateTime]="marathon.marathonStartDate" format="mediumDateTime"></app-element-temporal-datetime>
      (<app-element-temporal-distance [dateTime]="marathon.marathonStartDate"></app-element-temporal-distance>)
      </span>
    </app-element-table-cell>
  </app-element-table-row>
</app-element-table>
