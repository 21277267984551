<article class="message">
  <div class="message-header">
    <p>
      {{ 'user.settings.connections.title' | translate }} <br/>
      <small>{{ 'user.settings.connections.subtitle' | translate }}</small>
    </p>
  </div>
  <div class="message-body">
    <p class="pb-2">{{ 'user.settings.connections.visibility' | translate }}</p>
    <div class="row">
      <app-connection-settings *ngFor="let connection of connections"
                               class="column is-full"
                               [connection]="connection"
                               [discordId]="discordId"
                               [twitchId]="twitchId"
                               (deleteSelf)="deleteConnection(connection)"
      ></app-connection-settings>
    </div>
    <button type="button" (click)="addNewConnection()" class="button is-success is-outlined is-fullwidth">
      <fa-icon [icon]="faPlus"></fa-icon>
    </button>
  </div>
</article>
