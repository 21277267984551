<app-element-table-row>
  <app-element-table-cell class="duration">
    <span>
      <app-element-i18n [key]="durationText" [elementKey]="durationKey">
        <app-element-temporal-datetime data-key="start-time" [dateTime]="marathon.startDate" format="shortTime"></app-element-temporal-datetime>
        <app-element-temporal-datetime data-key="end-time" [dateTime]="marathon.endDate" format="shortTime"></app-element-temporal-datetime>
        <app-element-temporal-range data-key="time-range" [start]="marathon.startDate" [end]="marathon.endDate" format="shortTime"></app-element-temporal-range>
      </app-element-i18n>
    </span>
  </app-element-table-cell>
  <app-element-table-cell class="range">
    <app-element-range class="range-bar"
                       [class]="rangeColor"
                       min="0"
                       max="24"
                       [start]="start"
                       [end]="end"
    ></app-element-range>
  </app-element-table-cell>
  <app-element-table-cell class="name">
    <a [routerLink]="'/marathon/' + marathon.id | localize">
      {{ marathon.name }}
    </a>
  </app-element-table-cell>
  <app-element-table-cell class="links">
    <app-marathon-sidebar-overview [marathon]="marathon" [collapsed]="true" [isBigHome]="true"></app-marathon-sidebar-overview>
  </app-element-table-cell>
</app-element-table-row>
