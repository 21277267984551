<div class="level">
  <div class="level-left">
    <div class="title is-3 level-item">
      {{ 'marathon.schedule.title' | translate }}: {{ schedule.name }}
    </div>
  </div>
  <div class="level-right">
    <app-marathon-schedule-share [schedule]="schedule"></app-marathon-schedule-share>
    <app-marathon-schedule-export [scheduleId]="schedule.id"></app-marathon-schedule-export>
  </div>
</div>

<div class="current-runs-container block">
  <app-marathon-schedule-current *ngIf="currentRun" [ticker]="currentRun"></app-marathon-schedule-current>
  <app-marathon-schedule-current *ngIf="nextRun" [isNext]="true" [ticker]="nextRun"></app-marathon-schedule-current>
</div>

<p class="block">
  {{ 'marathon.schedule.timezone' | translate }}
</p>

<app-marathon-schedule-list
  [runs]="schedule.lines"
  [currentRun]="currentRun"
  [nextRun]="nextRun"
  [runHash]="runHash"
></app-marathon-schedule-list>
