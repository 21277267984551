<div>
  <img [src]="avatarUrl" alt="Profile image">
</div>

<div class="user-address mb-0">
  <h3 class="title is-3 username">
    <app-user-link [user]="fakeUserModel"></app-user-link>
  </h3>
  <span *ngIf="user.pronouns.length" class="tags">
      <span *ngFor="let pronoun of user.pronouns" class="tag is-info">
        {{ pronoun }}
      </span>
    </span>
</div>

<div class="user-address">
  <h5 class="title is-5">
    ({{ user.username }})
  </h5>
</div>

<div *ngIf="user.country" class="user-location">
  <span class="flag-icon" [class]="'flag-icon-' + user.country.toLowerCase()"></span>
  <span>
      {{ 'country.' + user.country | translate }}
    </span>
</div>

<div *ngIf="user.languagesSpoken.length">
  <span>{{ 'user.profile.speak' | translate }}</span>
  <span *ngFor="let language of user.languagesSpoken" class="language-spoken">
      {{ 'language.' + language | translate }}</span>
</div>
