<app-element-dropdown type="NAVBAR" [isMob]="true" [isRight]="true">
  <p trigger>
    {{ currentLocale.nativeName }}
  </p>
  <div options>
    <a *ngFor="let locale of usableLocales" class="navbar-item" href="#" (click)="changeLanguage(locale)">
      {{ languages[locale].nativeName }}
    </a>
  </div>
</app-element-dropdown>
