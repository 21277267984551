import { Component } from '@angular/core';

@Component({
  selector: 'app-element-table-detail',
  templateUrl: './element-table-detail.component.html',
  styleUrls: ['./element-table-detail.component.scss']
})
export class ElementTableDetailComponent {
  //
}
