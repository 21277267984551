<nav class="menu box" [class.collapsed]="collapsed">

  <button class="button navbar-burger" [class]="isActiveClass" (click)="toggleSidebar.emit(!collapsed)">
    <span></span>
    <span></span>
    <span></span>
  </button>

  <div class="menu-sections">
    <app-marathon-sidebar-overview [collapsed]="collapsed"
                          [marathon]="marathon"
                          class="menu-section"></app-marathon-sidebar-overview>
    <app-sidebar-tracker *ngIf="marathon.hasDonations || marathon.hasIncentives"
                         [collapsed]="collapsed" [marathon]="marathon"
                         class="menu-section"></app-sidebar-tracker>
    <app-sidebar-admin *ngIf="isAdmin"
                       [collapsed]="collapsed"
                       [marathon]="marathon"
                       class="menu-section"></app-sidebar-admin>
    <hr *ngIf="!collapsed">
    <app-sidebar-moderators *ngIf="!collapsed" [collapsed]="collapsed" [marathon]="marathon" class="menu-section"></app-sidebar-moderators>
  </div>
</nav>
