<div *ngIf="loading" class="loading-bar"></div>
<div class="default-layout-wrapper">
  <app-header-bar class="default-layout-header"></app-header-bar>

  <app-plum></app-plum>
  <main class="default-layout-main">
    <router-outlet (activate)="onRouteActivated($event)"></router-outlet>
  </main>

  <app-footer-bar class="default-layout-footer"></app-footer-bar>
</div>
