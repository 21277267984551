<app-element-dropdown [type]="type" [isRight]="isRight" class="sign-in-picker">
  <span trigger>
    {{ 'navbar.login.title' | translate }}
  </span>
  <div options>
    <a [href]="authService.getDiscordAuthUri()" (click)="storeCurrentPage()" [class]="dropdownItemClass">
      <fa-icon [icon]="iconDiscord"></fa-icon>
      <span>
        {{ 'navbar.login.discord' | translate }}
      </span>
    </a>
    <a [href]="authService.getTwitchAuthUrl()" (click)="storeCurrentPage()" [class]="dropdownItemClass">
      <fa-icon [icon]="iconTwitch"></fa-icon>
      <span>
        {{ 'navbar.login.twitch' | translate }}
      </span>
    </a>
    <!--<a href="" [class]="dropdownItemClass">
      <fa-icon [icon]="iconLoginNew"></fa-icon>
      <span>
        {{ 'navbar.login.password' | translate }}
      </span>
    </a>-->
  </div>
</app-element-dropdown>
