<section class="section container">
  <h1 class="title">Reset your password!</h1>

  <div class="notification" [class]="notificationClass" *ngIf="errorTranslationKey">
    <button class="delete" (click)="errorTranslationKey = null"></button>
    {{ errorTranslationKey | translate }}
  </div>

  <p>{{ 'auth.passwordReset.instructions' | translate }}</p>

  <div class="columns">
    <div class="column is-three-fifths is-offset-one-fifth">
      <form (submit)="requestNewPassword($event.target)">
        <fieldset [disabled]="loading">

          <div class="field">
            <label class="label">{{ 'user.settings.email.label' | translate }}</label>
            <div class="control has-icons-left">
              <input class="input" required type="email" placeholder="someone@example.com" name="email" [(ngModel)]="email">
              <span class="icon is-small is-left">
              <fa-icon [icon]="iconUser"></fa-icon>
            </span>
            </div>
          </div>

          <div class="control">
            <button type="submit" style="margin-right: 1rem"
                    [ngClass]="{'is-loading': loading}"
                    class="button is-primary">{{ 'auth.passwordReset.request' | translate }}</button>
          </div>

        </fieldset>
      </form>
    </div>
  </div>
</section>
