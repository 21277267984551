import { Component } from '@angular/core';

@Component({
  selector: 'app-element-table-row',
  templateUrl: './element-table-row.component.html',
  styleUrls: ['./element-table-row.component.scss']
})
export class ElementTableRowComponent {
  //
}
