<ng-template [ngIf]="user" [ngIfElse]="justUserName">
  <div class="is-inline-flex">
    <a *ngIf="showProfileLink"
       [routerLink]="'/user/' + user.username | localize">
      {{user.displayName}}
    </a>
    <span *ngIf="!showProfileLink">
    {{user.displayName}}
  </span>
    <ng-template [ngIf]="showSocialLinks && user.enabled">
      <a [href]="platforms[connection.platform] + connection.username" *ngFor="let connection of profileConnections" target="_blank">
        <fa-icon [icon]="iconMapping[connection.platform]" style="margin-left: 0.4rem"></fa-icon>
      </a>
    </ng-template>
  </div>
</ng-template>
<ng-template #justUserName>
  <a [routerLink]="'/user/' + username | localize">
    {{ username }}
  </a>
</ng-template>
