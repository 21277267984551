// Convert input options to more useable format
// and compile search regexp

'use strict';

function quoteRE(str) {
  return str.replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&');
}
module.exports = function normalize_opts(options) {
  var emojies = options.defs,
    shortcuts;

  // Filter emojies by whitelist, if needed
  if (options.enabled.length) {
    emojies = Object.keys(emojies).reduce(function (acc, key) {
      if (options.enabled.indexOf(key) >= 0) {
        acc[key] = emojies[key];
      }
      return acc;
    }, {});
  }

  // Flatten shortcuts to simple object: { alias: emoji_name }
  shortcuts = Object.keys(options.shortcuts).reduce(function (acc, key) {
    // Skip aliases for filtered emojies, to reduce regexp
    if (!emojies[key]) {
      return acc;
    }
    if (Array.isArray(options.shortcuts[key])) {
      options.shortcuts[key].forEach(function (alias) {
        acc[alias] = key;
      });
      return acc;
    }
    acc[options.shortcuts[key]] = key;
    return acc;
  }, {});
  var keys = Object.keys(emojies),
    names;

  // If no definitions are given, return empty regex to avoid replacements with 'undefined'.
  if (keys.length === 0) {
    names = '^$';
  } else {
    // Compile regexp
    names = keys.map(function (name) {
      return ':' + name + ':';
    }).concat(Object.keys(shortcuts)).sort().reverse().map(function (name) {
      return quoteRE(name);
    }).join('|');
  }
  var scanRE = RegExp(names);
  var replaceRE = RegExp(names, 'g');
  return {
    defs: emojies,
    shortcuts: shortcuts,
    scanRE: scanRE,
    replaceRE: replaceRE
  };
};