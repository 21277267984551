<div class="container patreon-nudge empty-space" *ngIf="showAdvertisement && false" [ngStyle]="{
  'width': dataWidth,
  'max-width': dataWidth,
  'min-height': dataMinHeight,
  'height' : dataHeight,
  'max-height' : dataHeight
}">
  <div class="join-patreon">
    <p>{{ 'global.ads.nag1' | translate }}</p> <br>
    <p>{{ 'global.ads.nag2' | translate }}</p>
  </div>
  <!-- TODO: new ads? -->
</div>
<div [class]="spacerClass"></div>
