<h1 class="title">{{ 'marathon.schedule.management.title' | translate}}</h1>

<div class="notification is-warning">{{'marathon.schedule.management.plsUseTheNewUi' | translate}}</div>


<div class="modal" [ngClass]="{ 'is-active': showModal }">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        <fa-icon [icon]="faExclamation"></fa-icon> {{ 'marathon.schedule.management.cacheWarning.header' | translate }}
      </p>
    </header>
    <section class="modal-card-body">
      <h5 class="title">{{ 'marathon.schedule.management.cacheWarning.title' | translate }}</h5>
      <p>{{ 'marathon.schedule.management.cacheWarning.line1' | translate }}</p>
      <p>{{ 'marathon.schedule.management.cacheWarning.line2' | translate }}</p>
      <p>{{ 'marathon.schedule.management.cacheWarning.line3' | translate }}</p>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success"
              (click)="realPublish()"
              [disabled]="buttonLoading"
              [ngClass]="{ 'is-loading': buttonLoading }">
        {{'marathon.schedule.management.action.publish' | translate}}
      </button>
    </footer>
  </div>
</div>

<p>{{ 'marathon.schedule.timezone' | translate}}</p>
<p *ngIf="marathonService.hasDstChange()">{{ 'marathon.schedule.dstChange' | translate}}</p>
<p>{{'marathon.schedule.management.help.dragAndDrop' | translate}}
  <fa-icon [icon]="faBars"></fa-icon>
</p>
<div class="columns is-multiline">
  <div class="column is-full">
    <div id="timeline"></div>
    <!-- custom data toggle -->
    <nwb-switch [(ngModel)]="hideCompleteUsers">
      <span>{{ 'marathon.schedule.management.hideCompleteUsers' | translate }}</span>
    </nwb-switch>
  </div>
  <div class="column is-full">
    <button type="button"
            class="button is-info is-pulled-right"
            (click)="clearAvailabilities()">
      {{'action.clearAvailabilities' | translate}}
    </button>
  </div>
</div>
<div class="columns is-desktop">
  <div class="column is-5">
    <h4 class="subtitle is-4">{{ 'marathon.schedule.management.selectedRuns' | translate}}</h4>
    <div class="table-container">
      <table class="table is-fullwidth is-striped" *ngIf="submissionsLoaded; else scheduleLoadingIndicator">
        <thead>
        <tr>
          <th>{{ 'marathon.submissions.table.runner' | translate}}</th>
          <th>{{ 'marathon.submissions.table.game' | translate}}</th>
          <th>{{ 'marathon.submissions.table.category' | translate}}</th>
          <th>{{ 'marathon.submissions.table.console' | translate}}</th>
          <th>{{ 'marathon.submissions.table.estimate' | translate}}</th>
          <th>{{ 'marathon.submissions.table.actions' | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let line of scheduleTodo; let i = index">
          <td>
            <div *ngFor="let runner of line.runners" class="is-flex">
              <a (click)="selectAvailabilities(getRunnerRawUsername(runner))" style="margin-right: 0.4rem"
                 *ngIf="!availabilitiesSelected.includes(getRunnerRawUsername(runner))">
                <fa-icon [icon]="faCalendarWeek"></fa-icon>
              </a>
              <a (click)="unselectAvailabilities(getRunnerRawUsername(runner))" style="margin-right: 0.4rem"
                 *ngIf="availabilitiesSelected.includes(getRunnerRawUsername(runner))">
                <fa-icon [icon]="faCalendarTimes"></fa-icon>
              </a>
              <app-user-link [username]="getRunnerUsername(runner)" ></app-user-link>
              <br/>
            </div>
          </td>
          <td>
            {{line.gameName}}
          </td>
          <td>{{line.categoryName}}
          </td>
          <td>
            {{line.console}}<sup *ngIf="line.emulated">{{ 'global.emu' | translate}}</sup>
          </td>
          <td>{{line.estimateHuman}}</td>
          <td>
            <a (click)="moveToSchedule(i)">
              <fa-icon [icon]="faChevronRight"></fa-icon>
            </a>
          </td>
        </tr>
        </tbody>
      </table>

      <ng-template #scheduleLoadingIndicator>
        <div class="is-centered">
          <app-loading-indicator></app-loading-indicator>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="column is-7">
    <h4 class="subtitle mb-0 is-4">{{ 'marathon.schedule.management.schedule' | translate}}</h4>
    <small class="mb-2">{{ 'marathon.schedule.management.markdown' | translate}}</small>

    <div class="table-container" [class]="{'table-container-editing-username': editInProgress}">
      <table class="table is-fullwidth is-striped">
        <thead>
        <tr>
          <th>{{ 'marathon.schedule.table.time' | translate}}</th>
          <th>{{ 'marathon.schedule.table.runner' | translate}}</th>
          <th>{{ 'marathon.schedule.table.game' | translate}}</th>
          <th>{{ 'marathon.schedule.table.category' | translate}}</th>
          <th>{{ 'marathon.schedule.table.type' | translate}}</th>
          <th>{{ 'marathon.schedule.table.console' | translate}}</th>
          <th>{{ 'marathon.schedule.table.estimate' | translate}}</th>
          <th>{{ 'marathon.schedule.table.setup' | translate}}</th>
          <th>{{ 'marathon.schedule.table.actions' | translate}}</th>
        </tr>
        </thead>
        <tbody cdkDropList
               [cdkDropListData]="schedule.lines"
               (cdkDropListDropped)="drop($event)">
        <tr *ngIf="!schedule.lines.length">
          <td colspan="8">{{ 'marathon.schedule.table.placeholder' | translate}}
          </td>
        </tr>
        <ng-template ngFor let-line [ngForOf]="schedule.lines" let-i="index">
          <tr *ngIf="i === 0 || moment(line.date).tz(timezone).isAfter(moment(schedule.lines[i-1].date), 'day')">
            <td colspan="9" class="is-primary has-text-centered"><b>{{moment(line.date).format('LL')}}</b></td>
          </tr>
          <tr cdkDrag *ngIf="!line.setupBlock">
            <td [ngClass]="{'is-warning': !matchesAvailabilities(line)}">{{moment(line.date).tz(timezone).format('HH:mm')}}</td>
            <td>
              <div class="is-flex" style="justify-content: space-between">
                <div class="is-flex" style="flex-direction: column">
                  <div *ngFor="let runner of line.runners; let j = index" class="is-flex">
                    <a *ngIf="editMode[line.position] && (line.runners.length > 1 || line.customRun)"
                       style="margin-right: 0.3rem" (click)="removeUser(j, line)">
                      <fa-icon [icon]="faTimes"></fa-icon>
                    </a>
                    <a (click)="selectAvailabilities(getRunnerRawUsername(runner))" style="margin-right: 0.4rem"
                       *ngIf="!availabilitiesSelected.includes(getRunnerRawUsername(runner))">
                      <fa-icon [icon]="faCalendarWeek"></fa-icon>
                    </a>
                    <a (click)="unselectAvailabilities(getRunnerRawUsername(runner))" style="margin-right: 0.4rem"
                       *ngIf="availabilitiesSelected.includes(getRunnerRawUsername(runner))">
                      <fa-icon [icon]="faCalendarTimes"></fa-icon>
                    </a>
                    <app-user-link [ngClass]="{ 'has-text-warning': !isAvailable(runner, line) }" [username]="getRunnerUsername(runner)"></app-user-link>
                  </div>
                  <div style="width: 10rem" *ngIf="editMode[line.position]">
                    <!-- TODO: how to trigger the not found display -->
                    <ng-autocomplete
                      [data]="userSearch[line.position]"
                      searchKeyword="username"
                      minQueryLength="3"
                      (selected)="onSelectUser($event, line)"
                      (inputChanged)="onSearchUser($event, line.position)"
                      [itemTemplate]="itemTemplate">
                    </ng-autocomplete>
                  </div>
                </div>
                <a (click)="toggleEditMode(line.position)"
                   *ngIf="!editInProgress || editMode[line.position]">
                  <fa-icon [icon]="faEdit"></fa-icon>
                </a>
              </div>
            </td>
            <td>
              <nwb-edit-in-place [(ngModel)]="line.gameName"></nwb-edit-in-place>
            </td>
            <td>
              <nwb-edit-in-place [(ngModel)]="line.categoryName"></nwb-edit-in-place>
            </td>
            <td>
            <span
              *ngIf="line.runners.length === 1 && !line.customRun">{{'marathon.schedule.type.SINGLE' | translate}}</span>
              <div class="select" *ngIf="line.runners.length > 1 || line.customRun">
                <select [(ngModel)]="line.type" name="type-{{i}}">
                  <option value="SINGLE"
                          *ngIf="line.customRun">{{ 'marathon.schedule.type.SINGLE' | translate}}</option>
                  <option value="RACE">{{ 'marathon.schedule.type.RACE' | translate}}</option>
                  <option value="COOP">{{ 'marathon.schedule.type.COOP' | translate}}</option>
                  <option value="COOP_RACE">{{ 'marathon.schedule.type.COOP_RACE' | translate}}</option>
                  <option value="RELAY">{{ 'marathon.schedule.type.RELAY' | translate}}</option>
                  <option value="RELAY_RACE">{{ 'marathon.schedule.type.RELAY_RACE' | translate}}</option>
                  <option value="OTHER">{{ 'marathon.schedule.type.OTHER' | translate}}</option>
                </select>
              </div>
            </td>
            <td>
            <span>
              <nwb-edit-in-place [(ngModel)]="line.console"></nwb-edit-in-place>
              <sup *ngIf="line.emulated">{{ 'global.emu' |translate}}</sup>
            </span>
            </td>
            <td>
              <nwb-edit-in-place [(ngModel)]="line.estimateHuman"
                                 [config]="durationEditConfig"
                                 (ngModelChange)="estimateChange(line, $event)"></nwb-edit-in-place>
            </td>
            <td>
              <nwb-edit-in-place [(ngModel)]="line.setupTimeHuman"
                                 [config]="setupDurationEditConfig"
                                 (ngModelChange)="setupChange(line, $event)"></nwb-edit-in-place>
            <td>
              <fa-icon [icon]="faBars" cdkDragHandle class="is-pulled-right"></fa-icon>
              <a class="is-pulled-right" (click)="moveToTodo(i)" style="margin-right: 0.4rem" *ngIf="!line.customRun">
                <fa-icon [icon]="faChevronLeft"></fa-icon>
              </a>
              <a class="is-pulled-right" (click)="removeLine(i)" style="margin-right: 0.4rem" *ngIf="line.customRun">
                <fa-icon [icon]="faTimes"></fa-icon>
              </a>
            </td>
          </tr>
          <tr cdkDrag *ngIf="line.setupBlock">
            <td>{{moment(line.date).format('HH:mm')}}</td>
            <td colspan="6" class="has-text-centered">
              <b>{{'marathon.schedule.setupBlock' | translate}}</b>
              <label class="checkbox" style="margin-left: 0.5rem">
                <input type="checkbox" name="setupBlockText-{{i}}" [(ngModel)]="line.useSetupBlockText">
                Custom label:
              </label>
              <nwb-edit-in-place [(ngModel)]="line.setupBlockText"></nwb-edit-in-place>
            </td>
            <td>
              <nwb-edit-in-place [(ngModel)]="line.setupTimeHuman"
                                 [config]="setupDurationEditConfig"
                                 (ngModelChange)="setupChange(line, $event)"></nwb-edit-in-place>
            <td>
              <fa-icon [icon]="faBars" cdkDragHandle class="is-pulled-right"></fa-icon>
              <a class="is-pulled-right" (click)="removeLine(i)" style="margin-right: 0.4rem">
                <fa-icon [icon]="faTimes"></fa-icon>
              </a>
            </td>
          </tr>
          <tr *ngIf="showCustomDataInput">
            <!-- OwO what's this -->
            <td colspan="9">
              <label for="'custom-data-index'+i">
                {{ 'marathon.schedule.management.customData.inputText' | translate }}
              </label>
              <textarea id="'custom-data-index'+i" class="textarea"
                        [(ngModel)]="line.customData"
                        placeholder='{"layout": "19:9", "other-data": "here"} or whatever else you want'></textarea>
            </td>
          </tr>
        </ng-template>
        </tbody>
      </table>
    </div>
    <p class="help">{{'marathon.schedule.management.help.save' | translate}}</p>
    <button type="button" class="button is-success is-pulled-right" style="margin-left: 1rem"
            (click)="publish()"
            [ngClass]="{'is-loading': loading}"
            *ngIf="!marathonService.marathon.scheduleDone">{{'marathon.schedule.management.action.publish' | translate}}
    </button>
    <button type="button" disabled class="button is-success is-pulled-right" style="margin-left: 1rem"
            *ngIf="marathonService.marathon.scheduleDone">{{'marathon.schedule.management.action.alreadyPublished' |
      translate}}
    </button>
    <button type="button" class="button is-primary is-pulled-right" style="margin-left: 1rem"
            [ngClass]="{'is-loading': loading}"
            (click)="submit()">{{'action.save' | translate}}</button>
    <button type="button" class="button is-info is-pulled-right" style="margin-left: 1rem"
            (click)="addLine(true)">{{'marathon.schedule.management.action.addSetupBlock' | translate}}
    </button>
    <button type="button" class="button is-info is-pulled-right" style="margin-left: 1rem"
            (click)="addLine(false)">{{'marathon.schedule.management.action.addRun' | translate}}
    </button>

    <!-- custom data toggle -->
    <nwb-switch [(ngModel)]="showCustomDataInput" name="discordPrivacy">
      <span>{{ 'marathon.schedule.management.customData.text' | translate }}</span>
    </nwb-switch>
  </div>
</div>

<ng-template #itemTemplate let-item>
  <!-- TODO: this potentially allows for HTML injection -->
  <a class="no-hover" *ngIf="item.isCustom; else oengusUser;" [innerHTML]="item.username + ' (custom)'"></a>
  <ng-template #oengusUser>
    <a class="no-hover" [innerHTML]="item.displayName + ' (' + item.username + ')'"></a>
  </ng-template>
</ng-template>
