<div class="marathon-details-info-container">
  <div class="info-box">
    <p class="info-label">
      {{ 'marathon.home.from' | translate }}
    </p>
    <app-element-temporal-datetime [dateTime]="marathon.startDate" format="shortDate" class="info"></app-element-temporal-datetime>
  </div>
  <div class="info-box">
    <p class="info-label">
      {{ 'marathon.home.to' | translate }}
    </p>
    <app-element-temporal-datetime [dateTime]="marathon.endDate" format="shortDate" class="info"></app-element-temporal-datetime>
  </div>
  <div class="info-box">
    <p class="info-label">
      {{ 'marathon.home.location' | translate }}
    </p>
    <app-marathon-location [marathon]="marathon" class="info"></app-marathon-location>
  </div>
  <div class="info-box">
    <p class="info-label">
      {{ 'marathon.home.language' | translate }}
    </p>
    <p class="info">
      {{ 'language.' + marathon.language | translate }}
    </p>
  </div>
  <a *ngIf="marathon.twitch" [href]="'https://twitch.tv/' +  marathon.twitch" class="info-box">
    <p class="info-label">
      {{ 'platform.TWITCH' | translate }}
    </p>
    <fa-icon [icon]="faTwitch" class="info"></fa-icon>
  </a>
  <a *ngIf="marathon.mastodon" [href]="mastodonUrl" class="info-box">
    <p class="info-label">
      {{ 'platform.MASTODON' | translate }}
    </p>
    <fa-icon [icon]="faMastodon" class="info"></fa-icon>
  </a>
  <a *ngIf="marathon.twitter" [href]="'https://twitter.com/' + marathon.twitter" class="info-box">
    <p class="info-label">
      {{ 'platform.TWITTER' | translate }}
    </p>
    <fa-icon [icon]="faTwitter" class="info"></fa-icon>
  </a>
  <a *ngIf="!marathon.discordPrivacy && marathon.discord" [href]="'https://discord.gg/' + marathon.discord" class="info-box">
    <p class="info-label">
      {{ 'platform.DISCORD' | translate }}
    </p>
    <fa-icon [icon]="faDiscord" class="info"></fa-icon>
  </a>

  <a *ngIf="marathon.youtube" [href]="marathon.youtube" class="info-box">
    <p class="info-label">
      {{ 'platform.YOUTUBE' | translate }}
    </p>
    <fa-icon [icon]="faYoutube" class="info"></fa-icon>
  </a>
</div>

<app-element-twitch-player
  *ngIf="marathon.twitch && isLive"
  [channel]="marathon.twitch"
  class="marathon-live-player"></app-element-twitch-player>

