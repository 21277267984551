<div class="container">
  <h1 class="title">{{'marathon.donations.title' | translate}}
    <button class="button is-pulled-right is-info" (click)="exportToCsv()"
            *ngIf="marathonService.isAdmin(userService.user)">{{ 'marathon.donations.export' | translate}}
    </button>
  </h1>
  <hr/>
  <nav class="level">
    <div class="level-item has-text-centered">
      <div>
        <p class="heading has-text-warning">{{ 'marathon.donations.total' | translate}}</p>
        <p class="title has-text-warning">
          <app-monetary-amount [amount]="stats.total"
                               [currency]="marathonService.marathon.donationCurrency"></app-monetary-amount>
        </p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">{{ 'marathon.donations.count' | translate}}</p>
        <p class="title">
          {{stats.count}}
        </p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">{{ 'marathon.donations.average' | translate}}</p>
        <p class="title">
          <app-monetary-amount [amount]="stats.average"
                               [currency]="marathonService.marathon.donationCurrency"></app-monetary-amount>
        </p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">{{ 'marathon.donations.max' | translate}}</p>
        <p class="title">
          <app-monetary-amount [amount]="stats.max"
                               [currency]="marathonService.marathon.donationCurrency"></app-monetary-amount>
        </p>
      </div>
    </div>
  </nav>
  <hr/>

  <table class="table is-fullwidth is-striped">
    <thead>
    <tr>
      <th>{{'marathon.donations.table.date' | translate}}</th>
      <th>{{'marathon.donations.table.name' | translate}}</th>
      <th>{{'marathon.donations.table.amount' | translate}}</th>
      <th>{{'marathon.donations.table.comment' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let donation of donations.content">
      <td>{{moment(donation.date).format('L LT')}}</td>
      <td>{{donation.nickname}}</td>
      <td>
        <app-monetary-amount [amount]="donation.amount"
                             [currency]="marathonService.marathon.donationCurrency"></app-monetary-amount>
      </td>
      <td>
        <p class="content">
          <app-oengus-md [data]="donation.comment"></app-oengus-md>
        </p>
      </td>
    </tr>
    </tbody>
  </table>
  <nwb-paginator
    [length]="donations.totalElements"
    [pageSize]="donations.size"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="donations.number"
    (page)="pageChange($event)"></nwb-paginator>
</div>
