<table class="table is-fullwidth">
  <thead>
  <tr>
    <th></th>
    <th>
      {{ 'marathon.schedule.table.time' | translate }}
    </th>
    <th>
      {{ 'marathon.schedule.table.runner' | translate }}
    </th>
    <th>
      {{ 'marathon.schedule.table.game' | translate }}
    </th>
    <th>
      {{ 'marathon.schedule.table.category' | translate }}
    </th>
    <th>
      {{ 'marathon.schedule.table.type' | translate }}
    </th>
    <th>
      {{ 'marathon.schedule.table.console' | translate }}
    </th>
    <th>
      {{ 'marathon.schedule.table.estimate' | translate }}
    </th>
    <th>
      {{ 'marathon.schedule.table.setup' | translate }}
    </th>
    <th>
      {{ 'marathon.schedule.table.actions' | translate }}
    </th>
  </tr>
  </thead>
  <tbody cdkDropList (cdkDropListDropped)="scheduleDrop($event)">
  <tr *ngIf="!lines.length">
    <td colspan="10">
      {{ 'marathon.schedule.table.placeholder' | translate}}
    </td>
  </tr>

  <ng-template ngFor let-line [ngForOf]="lines" let-i="index">
    <tr *ngIf="shouldShowDay(i)">
      <td colspan="10" class="day is-info">
        <app-element-temporal-datetime [dateTime]="line.date" format="longDateWithWeekday"></app-element-temporal-datetime>
      </td>
    </tr>

    <tr
      [class]="getRowParity(i)"
      cdkDrag
      [cdkDragDisabled]="expanded.has(line.position)"
    >
      <td>
        <app-element-table-cell
          [isExpandButton]="true"
          [expanded]="expanded.has(line.position)"
          (click)="toggleExpand(line.position)"
          class="expandable"
        ></app-element-table-cell>
      </td>
      <td [class]="{
        'is-warning': !matchesAvailabilities(line)
      }">
        <app-element-temporal-datetime [dateTime]="line.date" format="shortTime"></app-element-temporal-datetime>
      </td>

      <ng-template [ngIf]="line.setupBlock" [ngIfElse]="notASetupBlock">
        <td>
          <strong>{{'marathon.schedule.setupBlock' | translate}}</strong>
        </td>
        <td colspan="5">
          <app-simple-md [data]="line.setupBlockText || 'marathon.schedule.setupBlock' | translate"></app-simple-md>
        </td>
        <td>
          <app-element-temporal-duration [duration]="line.setupTime"></app-element-temporal-duration>
        </td>
      </ng-template>

      <ng-template #notASetupBlock>
        <td>
          <p *ngFor="let runner of line.runners" [class]="{
          'has-text-warning': !isAvailable(line, runner)
        }">
            <a (click)="selectAvailability.emit({
              username: getRunnerUsername(runner),
              on: false
           })"
               *ngIf="selectedAvailabilities.includes(getRunnerUsername(runner)); else notSelected">
              <fa-icon [icon]="iconCalendarTimes"></fa-icon>
            </a>
            <ng-template #notSelected>
              <a (click)="selectAvailability.emit({
              username: getRunnerUsername(runner),
              on: true
           })">
                <fa-icon [icon]="iconCalendarWeek"></fa-icon>
              </a>
            </ng-template>
            {{ getRunnerDisplayName(runner) }}
          </p>
        </td>
        <td>
          <app-simple-md [data]="line.game"></app-simple-md>
        </td>
        <td>
          <app-simple-md [data]="line.category"></app-simple-md>
        </td>
        <td>
          {{ 'marathon.schedule.type.' + line.type | translate }}
        </td>
        <td>
          {{ line.console }}<sup *ngIf="line.emulated">{{ 'global.emu' | translate}}</sup>
        </td>
        <td>
          <app-element-temporal-duration [duration]="line.estimate"></app-element-temporal-duration>
        </td>
        <td>
          <app-element-temporal-duration [duration]="line.setupTime"></app-element-temporal-duration>
        </td>
      </ng-template>

      <td class="actions">
        <!-- Setup blocks are custom runs imo, but for legacy reasons they are not -->
        <a (click)="moveToToDo.emit(i)" *ngIf="!line.customRun && !line.setupBlock">
          <fa-icon [icon]="iconChevronLeft"></fa-icon>
        </a>
        <a (click)="delete.emit(i)" *ngIf="line.customRun || line.setupBlock">
          <fa-icon [icon]="iconTimes"></fa-icon>
        </a>
        <a (click)="toggleExpand(line.position)">
          <fa-icon [icon]="iconEdit"></fa-icon>
        </a>
        <fa-icon [icon]="iconBars"
                 cdkDragHandle
                 [cdkDragHandleDisabled]="expanded.has(line.position)"
                 class="is-pulled-right"
        ></fa-icon>
      </td>
    </tr>

    <tr *ngIf="expanded.has(line.position) || showAllCustomData">
      <td colspan="10">
        <app-element-table-detail [class]="getRowParity(i)">
          <app-setup-block-editor *ngIf="line.setupBlock"
                                  (setupTimeChanged)="estimateChangedDebounce()"
                                  [line]="line"></app-setup-block-editor>
          <app-normal-run-editor *ngIf="!line.setupBlock"
                                 (estimateChanged)="estimateChangedDebounce()"
                                 (setupTimeChanged)="estimateChangedDebounce()"
                                 (loadAvailabilities)="loadAvailabilities.emit($event)"
                                 [line]="line"></app-normal-run-editor>

          <!-- (custom data exists for both setup block and normal runs) -->
          <div class="field custom-data-field">
            <label for="custom-data-{{i}}">
              {{ 'marathon.schedule.management.customData.inputText' | translate }}
            </label>

            <div class="control">
            <textarea id="custom-data-{{i}}" class="textarea"
                      [(ngModel)]="line.customData"
                      placeholder='{"layout": "19:9", "other-data": "here"} or whatever else you want'></textarea>
            </div>
          </div>
        </app-element-table-detail>
      </td>
    </tr>
  </ng-template>
  </tbody>
</table>
