<article class="message is-success {{status}}">
  <div class="message-header">
    <p>
      {{ category.name }} ({{ 'marathon.schedule.type.' + category.type | translate}}) - <app-element-temporal-duration [duration]="category.estimate"></app-element-temporal-duration>
      <a [href]="category.video" class="video" target="_blank" aria-label="Video" *ngIf="category.video">
                          <span class="icon">
                            <fa-icon [icon]="faFilm"></fa-icon>
                          </span>
      </a>
    </p>
    <p class="is-pulled-right" *ngIf="marathon.selectionDone">
      {{'marathon.selection.status.' + safeStatus | translate}}
    </p>
    <app-delete-button *ngIf="showDelete"
                       [baseText]="'marathon.submissions.category.delete' | translate"
                       [confirmText]="'action.confirm' | translate"
                       [cancelText]="'action.cancel' | translate"
                       (confirmHandler)="triggerDelete.next()"
    ></app-delete-button>
  </div>
  <div class="message-body">
    <ng-template [ngIf]="category.type !== 'SINGLE' && category.opponents && category.opponents.length > 0">
      <p>
        {{'marathon.submit.category.opponent.with' | translate}}
        <span *ngFor="let opponent of category.opponents; let i = index">
          <span *ngIf="i > 0">, </span>
          <app-user-link [user]="opponent.user" [isLink]="true" [showAvatar]="true"></app-user-link>
          <a [href]="opponent.video" target="_blank" aria-label="Video" *ngIf="opponent.video">
            <span class="icon">
              <fa-icon [icon]="faFilm"></fa-icon>
            </span>
          </a>
        </span>
      </p>
      <hr />
    </ng-template>
    <p>
      <app-simple-md [data]="category.description"></app-simple-md>
    </p>
  </div>
</article>
