<app-element-table class="marathon-calendar-table">
  <ng-template ngFor let-dailyCalendar="$implicit.marathons" let-endtime="$implicit.endtime" let-datetime="$implicit.datetime" [ngForOf]="realDaylyCallendars">
    <ng-template [ngIf]="dailyCalendar.length" [ngIfElse]="notArray">
      <app-element-table-cell [isHeader]="true"
                              class="day"
                              [class]="isToday(datetime)"
                              columnStart="1"
                              columnEnd="-1">
        <app-element-temporal-datetime [dateTime]="datetime" format="longDate"></app-element-temporal-datetime>
      </app-element-table-cell>

      <ng-template ngFor let-marathon let-index="index" [ngForOf]="dailyCalendar">
        <app-calendar-view-row [marathon]="marathon" [datetime]="datetime" [class]="getClasses(index)"></app-calendar-view-row>
      </ng-template>

    </ng-template>
    <ng-template #notArray>
      <app-element-table-cell [isHeader]="true"
                              class="day-range"
                              [class]="isToday(endtime, datetime)"
                              columnStart="1"
                              columnEnd="-1">
         <app-element-temporal-range [start]="endtime" [end]="datetime" format="longDate" ></app-element-temporal-range>
      </app-element-table-cell>
      <app-element-table-cell class="no-marathons is-even"
                              columnStart="1"
                              columnEnd="-1">
         {{ 'calendar.noMarathons' | translate }}
      </app-element-table-cell>
    </ng-template>
  </ng-template>
</app-element-table>
