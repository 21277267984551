<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label for="setup-text-{{i}}">{{ 'marathon.schedule.management.settings.customLabel' | translate }}:</label>
  </div>
  <div class="field-body">
    <div class="field">
      <input class="input" type="text" name="setup-text-{{i}}" id="setup-text-{{i}}" [(ngModel)]="line.setupBlockText" placeholder="Setup Block"/>
      <p class="help">
        {{ 'marathon.schedule.management.help.setupBlock' | translate }}
      </p>
    </div>
  </div>
</div>

<div class="field is-horizontal">
  <div class="field-label is-normal">
    <label for="setup-{{i}}">{{ 'marathon.schedule.table.setup' | translate}}:</label>
  </div>
  <div class="field-body">
    <div class="field">
      <input class="input"
             type="text"
             name="setup-{{i}}"
             id="setup-{{i}}"
             (blur)="onSetupTimeBlur()"
             [(ngModel)]="humanSetupTime"/>
    </div>
  </div>
</div>
