<h1 class="title">{{'marathon.submit.title' | translate}}</h1>

<hr/>

<div class="modal" [ngClass]="{ 'is-active': discordRequired }">
  <div class="modal-background"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{'marathon.submit.requirements.discord.title' | translate}}</p>
    </header>
    <section class="modal-card-body">
      <h5 class="title">{{'marathon.submit.requirements.discord.lead' | translate}}</h5>
      <p>{{'marathon.submit.requirements.discord.par1' | translate}}</p>
      <p>{{'marathon.submit.requirements.discord.par2' | translate}}</p>
      <p *ngIf="!userHasDiscord">
        {{ 'marathon.submit.requirements.discord.syncNotice.0' | translate }}
        <a [routerLink]="'/user/settings' | localize">{{
          'marathon.submit.requirements.discord.syncNotice.1' | translate
          }}</a>{{ 'marathon.submit.requirements.discord.syncNotice.2' | translate }}
        <!-- ^ ugly hack for punctuation stuff -->
      </p>

      <article class="message is-danger" *ngIf="discordErrors.botNotInGuild || discordErrors.userNotInGuild">
        <div class="message-body">
          <p *ngIf="discordErrors.botNotInGuild">{{'marathon.submit.requirements.discord.errors.botMissing' | translate}}</p>
          <p [innerHTML]="'marathon.submit.requirements.discord.errors.userMissing' | translate: { invite: marathonDiscord }"
            *ngIf="discordErrors.userNotInGuild"></p>
        </div>
      </article>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success"
              (click)="checkUserInDiscord()"
              [disabled]="!userHasDiscord || isDiscordCheckLoading"
              [ngClass]="{ 'is-loading': isDiscordCheckLoading }">
        {{'marathon.submit.requirements.discord.check' | translate}}
      </button>
      <button class="button" (click)="goBack()" [disabled]="isDiscordCheckLoading">
        {{'marathon.submit.requirements.discord.back' | translate}}
      </button>
    </footer>
  </div>
</div>

<nav class="level is-mobile is-flex-wrap-wrap">
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">{{'marathon.home.from' | translate}}</p>
      <p class="title">{{moment(marathonService.marathon.startDate).tz(timezone).format('L LT')}}</p>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">{{'marathon.home.to' | translate}}</p>
      <p class="title">{{moment(marathonService.marathon.endDate).tz(timezone).format('L LT')}}</p>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">{{'marathon.submit.maxGamesPerRunner' | translate}}</p>
      <p class="title"
         *ngIf="marathonService.marathon.unlimitedGames; else limitedGames">{{'marathon.settings.unlimited' | translate}}</p>
      <ng-template #limitedGames>
        <p class="title">{{marathonService.marathon.maxGamesPerRunner}}</p>
      </ng-template>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">{{'marathon.submit.maxCategoriesPerGame' | translate}}</p>
      <p class="title"
         *ngIf="marathonService.marathon.unlimitedCategories; else limitedCategories">{{'marathon.settings.unlimited' | translate}}</p>
      <ng-template #limitedCategories>
        <p class="title">{{maxCategoriesPerGame}}</p>
      </ng-template>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">{{'marathon.submit.racesAllowed' | translate}}</p>
      <p class="title">
        <fa-icon [icon]="faCheck"
                 *ngIf="marathonService.marathon.hasMultiplayer; else noMultiplayer"></fa-icon>
        <ng-template #noMultiplayer>
          <fa-icon [icon]="faTimes"></fa-icon>
        </ng-template>
      </p>
    </div>
  </div>
  <div class="level-item has-text-centered">
    <div *ngIf="marathonService.marathon.hasMultiplayer">
      <p class="heading">{{'marathon.submit.maxNumberOfScreens' | translate}}</p>
      <p class="title">{{marathonService.marathon.maxNumberOfScreens}}</p>
    </div>
  </div>
</nav>

<hr/>

<div class="notification is-info" *ngIf="!marathonService.marathon.submitsOpen">
  {{'marathon.submit.submitsClosed' | translate}}
</div>

<form ngForm="submitForm" (ngSubmit)="submit()" #form="ngForm">
  <h3 class="title is-3">{{'marathon.submit.availabilities.label' | translate}} <br/>
    <span class="is-size-6" [innerHTML]="'footer.text' | translate:{'timezone': timezone}"></span>
    <button type="submit" class="button is-primary is-pulled-right" [ngClass]="{'is-loading': loading}"
            [disabled]="form.invalid || submission.availabilities.length === 0 || submission.games.length === 0 &&
            submission.opponents.length === 0">{{'action.submit' |
      translate}}
    </button>
    <a type="button"
       class="button is-pulled-right"
       style="background-color: #00aced; border-color:transparent; color:#fff; margin-right: 1rem"
       *ngIf="submission.id > 0 && !marathonService.marathon.isPrivate"
       target="_blank"
       href="https://twitter.com/intent/tweet?text={{'marathon.submit.tweet.submit' | translate:{'games':getGames(),
     'marathon': marathonService.marathon.name} }}&via=OengusIO&lang={{localStorage.getItem('language')}}&url=https://oengus.fun/{{marathonService.marathon.id}}">
      <fa-icon [icon]="faTwitter" style="margin-right: 0.4rem"></fa-icon>
      {{'action.sendTweet' | translate}}
    </a>
    <app-element-share-mastodon class="is-pulled-right"
                                *ngIf="submission.id > 0 && !marathonService.marathon.isPrivate"
                                text="{{'marathon.submit.tweet.submit' | translate:{'games':getGames(),
     'marathon': marathonService.marathon.name} }}%0Ahttps://oengus.fun/{{marathonService.marathon.id}}"></app-element-share-mastodon>
  </h3>
  <p *ngIf="marathonService.hasDstChange()">{{ 'marathon.schedule.dstChange' | translate}}</p>
  <button type="button"
          class="button is-primary"
          (click)="addAvailability()"
          style="margin-bottom: 1rem">
    {{'marathon.submit.availabilities.add' | translate}}
  </button>
  <p class="help is-danger" *ngIf="submission.availabilities.length === 0">
    {{'marathon.submit.availabilities.error.required' | translate}}</p>
  <table class="table" *ngIf="submission.availabilities.length > 0">
    <thead>
    <tr>
      <th><span>{{'marathon.submit.availabilities.from' | translate}}</span></th>
      <th><span>{{'marathon.submit.availabilities.to' | translate}}</span></th>
      <th><span>{{'marathon.submit.availabilities.actions' | translate}}</span></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let availability of submission.availabilities; let i = index">
      <td><input class="input"
                 readonly
                 autocomplete="disabled"
                 [ngClass]="{'is-danger': fromDate.invalid}"
                 [owlDateTimeTrigger]="dtFromDate"
                 [owlDateTime]="dtFromDate"
                 [min]="marathonService.marathon.startDate"
                 [max]="!!availability.to ? availability.to : marathonService.marathon.endDate"
                 [(ngModel)]="availability.from"
                 name="fromDate{{i}}"
                 required
                 #fromDate="ngModel">
        <owl-date-time #dtFromDate></owl-date-time>
        <div *ngIf="fromDate.invalid">
          <p class="help is-danger" *ngIf="fromDate.errors.required">
            {{'marathon.submit.availabilities.error.dateRequired' | translate}}</p>
        </div>
      </td>
      <td><input class="input"
                 readonly
                 autocomplete="disabled"
                 [ngClass]="{'is-danger': toDate.invalid}"
                 [owlDateTimeTrigger]="dtToDate"
                 [owlDateTime]="dtToDate"
                 [(ngModel)]="availability.to"
                 [min]="minToAvailability(availability)"
                 [max]="marathonService.marathon.endDate"
                 name="toDate{{i}}"
                 required
                 #toDate="ngModel">
        <owl-date-time #dtToDate></owl-date-time>
        <div *ngIf="toDate.invalid">
          <p class="help is-danger" *ngIf="toDate.errors.required">
            {{'marathon.submit.availabilities.error.dateRequired' | translate}}</p>
        </div>
        <p class="help is-danger" *ngIf="toDate.errors?.owlDateTimeMin">
          {{ 'marathon.submit.availabilities.error.toDateTooLittle' | translate }}
        </p>
      </td>
      <td style="vertical-align: middle">
        <a (click)="duplicateAvailabilityToNextDay(i)" *ngIf="!!availability.from && !!availability.to"
           style="margin-right: 0.5rem" [nwbToolTip]="'marathon.submit.availabilities.duplicate' | translate">
          <fa-icon [icon]="faClone"></fa-icon>
        </a>
        <button class="delete" (click)="removeAvailability(i)" style="margin: auto"></button>
      </td>
    </tr>
    </tbody>
  </table>
  <fieldset [disabled]="!marathonService.marathon.submitsOpen">
    <ng-container *ngIf="submission.answers?.length > 0">
      <h3 class="title is-3">{{'marathon.submit.details' | translate}}</h3>
      <div class="field" *ngFor="let answer of submission.answers; let i = index">
        <label class="label" *ngIf="answer.question.fieldType !== 'CHECKBOX'">{{answer.question.label}}</label>
        <div class="control" *ngIf="answer.question.fieldType === 'TEXT'">
          <input class="input"
                 type="text"
                 [(ngModel)]="answer.answer"
                 name="answer{{i}}"
                 [ngClass]="{'is-danger': answerField.invalid}"
                 [required]="answer.question.required"
                 maxlength="50"
                 #answerField="ngModel">
          <p class="help is-pulled-right">{{answer.answer?.length}}/50</p>
          <div *ngIf="answerField.invalid">
            <p class="help is-danger" *ngIf="answerField.errors.required">
              {{'marathon.submit.customField.error.required' | translate}}</p>
          </div>
        </div>
        <div class="control" *ngIf="answer.question.fieldType === 'TEXTAREA'">
        <textarea class="textarea"
                  rows=3
                  maxlength="500"
                  [(ngModel)]="answer.answer"
                  name="answer{{i}}"
                  [ngClass]="{'is-danger': answerField.invalid}"
                  [required]="answer.question.required"
                  #answerField="ngModel"></textarea>
          <p class="help is-pulled-right">{{answer.answer?.length}}/500</p>
          <div *ngIf="answerField.invalid">
            <p class="help is-danger" *ngIf="answerField.errors.required">
              {{'marathon.submit.customField.error.required' | translate}}</p>
          </div>
        </div>
        <div class="control" *ngIf="answer.question.fieldType === 'SELECT'">
        <span class="select">
              <select [(ngModel)]="answer.answer" name="answer{{i}}" #answerField="ngModel"
                      [ngClass]="{'is-danger': answerField.invalid}" [required]="answer.question.required">
                <option
                  *ngFor="let option of answer.question.options" [ngValue]="option">{{option}}</option>
              </select>
            </span>
          <div *ngIf="answerField.invalid">
            <p class="help is-danger" *ngIf="answerField.errors.required">
              {{'marathon.submit.customField.error.required' | translate}}</p>
          </div>
        </div>
        <div class="control" *ngIf="answer.question.fieldType === 'FREETEXT'">
          <app-oengus-md [data]="answer.question.description"></app-oengus-md>
        </div>
        <div class="control" *ngIf="answer.question.fieldType === 'CHECKBOX'">
          <nwb-switch [(ngModel)]="answer.answer"
                      [required]="answer.question.required"
                      name="answer{{i}}"
                      #answerField="ngModel">{{answer.question.label}}</nwb-switch>
          <div *ngIf="answerField.invalid">
            <p class="help is-danger" *ngIf="answerField.errors.required">
              {{'marathon.submit.customField.error.required' | translate}}</p>
          </div>
        </div>
      </div>
    </ng-container>
    <h3 class="title is-3">{{'marathon.submit.submissions' | translate}}</h3>

    <div class="box is-relative">
      <p class="is-pulled-left">{{'marathon.submit.table.games' | translate}}</p>
      <button type="button" class="button is-small is-primary is-pulled-left" style="margin-left: 5px" (click)="addGame()"
              [disabled]="!marathonService.marathon.unlimitedGames && submission.games.length >=
                    marathonService.marathon.maxGamesPerRunner">
        <fa-icon [icon]="faPlus"></fa-icon>
      </button>
      <div class="is-clearfix"></div>

      <ng-template ngFor let-game [ngForOf]="submission.games" let-i="index">
        <hr class="has-background-grey"/>
        <button class="delete is-absolute"
                style="right: 10px;"
                (click)="removeGame(i)"></button>

        <div class="columns is-multiline">
          <div class="column is-half">
            <div class="field">
              <label for="gameName{{i}}" class="label">{{'marathon.submit.game.name.label' | translate}}</label>
              <div class="control">
                <input class="input"
                       type="text"
                       [(ngModel)]="game.name"
                       id="gameName{{i}}"
                       name="gameName{{i}}"
                       [ngClass]="{'is-danger': gameName.invalid}"
                       required
                       maxlength="100"
                       #gameName="ngModel">
                <p class="help is-pulled-right">{{game.name?.length}}/100</p>
              </div>
              <div *ngIf="gameName.invalid">
                <p class="help is-danger" *ngIf="gameName.errors.required">
                  {{'marathon.submit.game.name.error.required' | translate}}</p>
              </div>
            </div>
          </div>

          <div class="column is-half">
            <div class="field">
              <label class="label">{{'marathon.submit.game.ratio.label' | translate}}</label>
              <div class="control">
                <input class="input"
                       type="text"
                       [(ngModel)]="game.ratio"
                       name="gameRatio{{i}}"
                       [ngClass]="{'is-danger': gameRatio.invalid}"
                       required
                       maxlength="10"
                       #gameRatio="ngModel">
                <p class="help is-pulled-right">{{game.ratio?.length}}/10</p>
              </div>
              <div *ngIf="gameRatio.invalid">
                <p class="help is-danger" *ngIf="gameRatio.errors.required">
                  {{'marathon.submit.game.ratio.error.required' | translate}}</p>
              </div>
            </div>
          </div>

          <div class="column">
            <label class="label">{{'marathon.submit.game.console.label' | translate}}</label>
            <div class="field has-addons">
              <div class="select is-fullwidth">
                <select [(ngModel)]="game.console" name="gameConsole{{i}}">
                  <option value="" disabled></option>
                  <option *ngFor="let opt of possibleConsoles" [ngValue]="opt">{{opt}}</option>
                </select>
              </div>
              <!-- TODO: why do we have the same set twice here? -->
              <fieldset class="control is-tooltip-danger" [disabled]="!marathonService.marathon.emulatorAuthorized"
                        [nwbToolTip]="'marathon.submit.game.console.emulatorNotAllowed' | translate"
                        *ngIf="!marathonService.marathon.emulatorAuthorized">
                <button type="button" class="button">
                  <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="game.emulated" name="emulated{{i}}"
                           style="margin-right: 0.25rem">{{'marathon.submit.game.console.emulated' | translate}}
                  </label>
                </button>
              </fieldset>
              <p class="control" *ngIf="marathonService.marathon.emulatorAuthorized">
                <button type="button" class="button" (click)="clickEmulatorButton(game, $event)">
                  <label class="checkbox">
                    <input type="checkbox" [checked]="game.emulated" name="emulated{{i}}"
                           style="margin-right: 0.25rem">{{'marathon.submit.game.console.emulated' | translate}}
                  </label>
                </button>
              </p>
            </div>
          </div>
        </div>

        <div class="field">
          <label class="label">{{'marathon.submit.game.description.label' | translate}}</label>
          <p class="help">{{'marathon.submit.game.description.help' | translate}}</p>
          <div class="control">
            <textarea class="textarea"
                      rows=3
                      maxlength="500"
                      [(ngModel)]="game.description"
                      name="gameDescription{{i}}"
                      [ngClass]="{'is-danger': gameDescription.invalid}"
                      required
                      #gameDescription="ngModel"></textarea>
            <p class="help is-pulled-right">{{game.description?.length}}/500</p>
          </div>
          <div *ngIf="gameDescription.invalid">
            <p class="help is-danger" *ngIf="gameDescription.errors.required">
              {{'marathon.submit.game.description.error.required' | translate}}</p>
          </div>
        </div>

        <div class="is-clearfix"></div>

        <!-- categories below this line -->
        <p>
          {{'marathon.submit.table.categories' | translate}}
          <button type="button"
                  class="button is-small is-primary"
                  style="margin-left: 5px"
                  *ngIf="maxCategoriesPerGame > 1"
                  (click)="addCategory(i)"
                  [disabled]="!marathonService.marathon.unlimitedCategories && game.categories.length >= maxCategoriesPerGame">
            <fa-icon [icon]="faPlus"></fa-icon>
          </button>
        </p>
        <hr class="has-background-grey-darker"/>
        <div class="columns is-multiline">
          <ng-template ngFor let-category [ngForOf]="game.categories" let-j="index">
            <!-- TODO: make half width [ngClass]="{ 'is-half': (game.categories.length > 1) }", figure out button -->
            <div class="column is-full">
              <article class="message">
                <div class="message-body">
                  <button class="delete is-absolute"
                          style="right: 25px;"
                          (click)="removeCategory(i, j)"
                          *ngIf="game.categories.length > 1"></button>

                  <div class="columns is-multiline">
                    <div class="column is-6">
                      <div class="field">
                        <label class="label">{{'marathon.submit.category.name.label' | translate}}</label>
                        <div class="control">
                          <input class="input"
                                 type="text"
                                 [(ngModel)]="category.name"
                                 name="categoryName{{i}}-{{j}}"
                                 [ngClass]="{'is-danger': categoryName.invalid}"
                                 required
                                 maxlength="100"
                                 #categoryName="ngModel">
                          <p class="help is-pulled-right">{{category.name?.length}}/100</p>
                        </div>
                        <div *ngIf="categoryName.invalid">
                          <p class="help is-danger" *ngIf="categoryName.errors.required">
                            {{'marathon.submit.category.name.error.required' | translate}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="column is-6">
                      <div class="field">
                        <label class="label">{{'marathon.submit.category.estimate.label' | translate}}</label>
                        <input class="input"
                               type="text"
                               [(ngModel)]="category.estimateHuman"
                               name="categoryEstimate{{i}}-{{j}}"
                               placeholder="HH:MM:SS"
                               [ngClass]="{'is-danger': categoryEstimate.invalid}"
                               required
                               pattern="^(?:\d{1,3}):(?:[012345]\d):(?:[012345]\d)$"
                               appMinDurationValidator="1"
                               #categoryEstimate="ngModel">
                        <p class="help">{{'marathon.submit.category.estimate.hint' | translate}}</p>
                        <div *ngIf="categoryEstimate.invalid">
                          <p class="help is-danger" *ngIf="categoryEstimate.errors.required">
                            {{'marathon.submit.category.estimate.error.required' | translate}}</p>
                          <p class="help is-danger" *ngIf="categoryEstimate.errors.pattern">
                            {{'marathon.submit.category.estimate.error.pattern' | translate}}</p>
                          <p class="help is-danger" *ngIf="categoryEstimate.errors.minDuration">
                            {{'marathon.submit.category.estimate.error.min' | translate}}</p>
                        </div>
                      </div>
                    </div>

                    <div class="column is-6">
                      <div class="field">
                        <label class="label">{{'marathon.submit.category.video.label' | translate}}</label>
                        <div class="control">
                          <input class="input"
                                 type="url"
                                 [(ngModel)]="category.video"
                                 [ngClass]="{'is-danger': categoryVideo.invalid || !testVideo.checkValidity()}"
                                 name="video{{i}}-{{j}}"
                                 [required]="marathonService.marathon.videoRequired"
                                 maxlength="100"
                                 #categoryVideo="ngModel" #testVideo>
                          <p class="help is-pulled-right">{{category.video?.length}}/100</p>
                        </div>
                        <div *ngIf="categoryVideo.invalid">
                          <p class="help is-danger" *ngIf="categoryVideo.errors.required">
                            {{'marathon.submit.category.video.error.required' | translate}}</p>
                        </div>
                        <p class="help is-danger" *ngIf="!testVideo.checkValidity()">
                          {{'errors.format.url' | translate}}
                        </p>
                      </div>
                    </div>

                    <div class="column is-6">
                      <div class="field">
                        <label class="label">{{'marathon.submit.category.type.label' | translate}}</label>
                        <div class="select is-fullwidth">
                          <select [(ngModel)]="category.type" [disabled]="!marathonService.marathon.hasMultiplayer"
                                  name="type-{{i}}-{{j}}">
                            <option value="SINGLE"
                                    [disabled]="category.opponents && category.opponents.length > 0">
                              {{'marathon.schedule.type.SINGLE' | translate}}
                            </option>
                            <option value="RACE">{{ 'marathon.schedule.type.RACE' | translate}}</option>
                            <option value="COOP">{{ 'marathon.schedule.type.COOP' | translate}}</option>
                            <option value="COOP_RACE">{{ 'marathon.schedule.type.COOP_RACE' | translate}}</option>
                            <option value="RELAY">{{ 'marathon.schedule.type.RELAY' | translate}}</option>
                            <option value="RELAY_RACE">{{ 'marathon.schedule.type.RELAY_RACE' | translate}}</option>
                          </select>
                        </div>
                        <div *ngIf="['RACE', 'COOP', 'COOP_RACE', 'RELAY', 'RELAY_RACE'].includes(category.type)">
                          <p class="help" *ngIf="!category.code">
                            {{'marathon.submit.category.code.help.noCode' | translate}}
                          </p>
                          <p class="help"
                             *ngIf="category.opponents && category.opponents.length > 0">{{'marathon.submit.category.opponent.with' |
                            translate}}
                            <span *ngFor="let opponent of category.opponents; let i = index">
                              <span *ngIf="i > 0">, </span>
                                <app-user [user]="opponent.user" [showSocialLinks]="true"></app-user>
                            </span>
                          </p>
                          <p class="help" *ngIf="category.code">
                            {{'marathon.submit.category.code.help.withCode' | translate}} <b>{{category.code}}</b></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">{{'marathon.submit.category.description.label' | translate}}</label>
                    <p class="help">{{'marathon.submit.category.description.help' | translate}}</p>
                    <div class="control">
                  <textarea class="textarea"
                            rows=3
                            maxlength="300"
                            [(ngModel)]="category.description"
                            [ngClass]="{'is-danger': categoryDescription.invalid}"
                            name="categoryDescription{{i}}-{{j}}"
                            required
                            #categoryDescription="ngModel"></textarea>
                      <p class="help is-pulled-right">{{category.description?.length}}/300</p>
                    </div>
                    <div *ngIf="categoryDescription.invalid">
                      <p class="help is-danger" *ngIf="categoryDescription.errors.required">
                        {{'marathon.submit.category.description.error.required' | translate}}</p>
                    </div>
                  </div>

                  <div class="is-clearfix"></div>
                </div>
              </article>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </div>

    <h3 class="title is-3">{{'marathon.submit.multiplayer.title' | translate}}</h3>
    <p>{{'marathon.submit.multiplayer.code.help' | translate}}</p>
    <div class="field has-addons">
      <div class="control">
        <input class="input"
               type="text"
               [(ngModel)]="code"
               name="code"
               maxlength="6">
      </div>
      <div class="control">
        <button type="button" class="button is-primary" (click)="getMultiplayerSubmission()"
                [disabled]="!code">{{'action.join' | translate}}</button>
      </div>
    </div>
    <table class="table is-striped" *ngIf="submission.opponents && submission.opponents.length">
      <thead>
      <tr>
        <th>{{'marathon.submit.table.runners' | translate}}</th>
        <th>{{'marathon.submit.game.name.label' | translate}}</th>
        <th>{{'marathon.submit.category.name.label' | translate}}</th>
        <th>{{'marathon.submit.category.video.label' | translate}}</th>
        <th>{{'marathon.submit.table.actions' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let opponent of submission.opponents; let i = index">
        <td>
          <div *ngFor="let user of opponent.users" class="is-flex">
            <app-user-link [user]="user" [isLink]="true" target="_blank"></app-user-link>
          </div>
        </td>
        <td>{{opponent.gameName}}</td>
        <td>{{opponent.categoryName}}</td>
        <td>
          <div class="field">
            <div class="control">
              <input class="input"
                     type="text"
                     [(ngModel)]="opponent.video"
                     [ngClass]="{'is-danger': opponentVideo.invalid}"
                     name="opponentVideo{{i}}"
                     [required]="marathonService.marathon.videoRequired"
                     maxlength="100"
                     #opponentVideo="ngModel">
              <p class="help is-pulled-right">{{opponent.video?.length}}/100</p>
            </div>
            <div *ngIf="opponentVideo.invalid">
              <p class="help is-danger" *ngIf="opponentVideo.errors.required">
                {{'marathon.submit.category.video.error.required' | translate}}</p>
            </div>
          </div>
        </td>
        <td style="vertical-align: middle">
          <button class="delete" (click)="removeMultiplayer(i)" style="margin: auto"></button>
        </td>
      </tr>
      </tbody>
    </table>
  </fieldset>
  <div class="is-clearfix">
    <button type="submit" class="button is-primary is-pulled-right" [ngClass]="{'is-loading': loading}"
            [disabled]="form.invalid || submission.availabilities.length === 0  || submission.games.length === 0 &&
            submission.opponents.length === 0">{{'action.submit' |
      translate}}
    </button>
    <a type="button"
       class="button is-pulled-right"
       style="background-color: #00aced; border-color:transparent; color:#fff; margin-right: 1rem"
       *ngIf="submission.id > 0 && !marathonService.marathon.isPrivate"
       target="_blank"
       href="https://twitter.com/intent/tweet?text={{'marathon.submit.tweet.submit' | translate:{'games':getGames(),
     'marathon': marathonService.marathon.name} }}&via=OengusIO&lang={{localStorage.getItem('language')}}&url=https://oengus.fun/{{marathonService.marathon.id}}">
      <fa-icon [icon]="faTwitter" style="margin-right: 0.4rem"></fa-icon>
      {{'action.sendTweet' | translate}}
    </a>
    <app-element-share-mastodon class="is-pulled-right"
                                *ngIf="submission.id > 0 && !marathonService.marathon.isPrivate"
                                text="{{'marathon.submit.tweet.submit' | translate:{'games':getGames(),
     'marathon': marathonService.marathon.name} }}%0Ahttps://oengus.fun/{{marathonService.marathon.id}}"></app-element-share-mastodon>
  </div>
  <div *ngIf="submission.id > 0 && !marathonService.marathon.scheduleDone" class="is-clearfix">
    <hr>
    <h1 class="title">{{ 'marathon.submit.dangerZone' | translate}}</h1>
    <div class="field">
      <div class="control" *ngIf="!deleteConfirm">
        <button class="button is-danger" type="button" (click)="deleteConfirm = true">
          {{ 'marathon.submit.delete.action' | translate}}
        </button>
      </div>
      <div class="control" *ngIf="deleteConfirm">
        <button class="button is-primary" type="button"
                (click)="deleteSubmission(marathonService.marathon.id, submission.id)">
          {{'action.confirm' | translate}}
        </button>
        <button class="button is-danger" type="button" (click)="deleteConfirm = false"
                style="margin-left: 1rem">
          {{'action.cancel' | translate}}
        </button>
      </div>
    </div>
  </div>
</form>
