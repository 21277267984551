<h1 class="title">{{ 'marathon.schedule.management.title' | translate}}</h1>

<div>
  <p>
    {{ 'marathon.schedule.management.overview.title' | translate: { 'create-new': '' } }}
    <ng-template [ngIf]="(isSponsor && schedules.length < 4) || schedules.length === 0" [ngIfElse]="disabledButton">
      <a [routerLink]="'../new' | localize"
         class="button is-success">
        {{ 'marathon.schedule.management.overview.create-new' | translate }}
      </a>
    </ng-template>
    <ng-template #disabledButton>
      <button type="button" class="button is-success" disabled>
        {{ 'marathon.schedule.management.overview.create-new' | translate }}
      </button>
    </ng-template>
  </p>
  <p class="help">
    {{ 'marathon.schedule.management.help.patronNudge' | translate}}
  </p>
</div>

<hr>

<ng-template
  [ngIf]="schedules.length"
  [ngIfElse]="noSchedules"
>
  <h2 class="title">{{ 'marathon.schedule.management.overview.scheduleHeader' | translate }}</h2>
  <ul *ngFor="let schedule of schedules" class="schedule-list">
    <li class="mb-2">
      <p>{{ schedule.name }}:</p>

      <div class="buttons">
        <a class="button is-info" [routerLink]="'../' + schedule.id | localize" >{{ 'action.edit' | translate }}</a>
        <button (click)="deleteSchedule(schedule)" type="button" class="button is-danger">{{ 'action.delete' | translate }}</button>
      </div>
    </li>
  </ul>
</ng-template>
<ng-template #noSchedules>
  <p>{{ 'marathon.schedule.management.overview.nothingFound' | translate }}</p>
</ng-template>
