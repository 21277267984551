<div class="container">
  <section class="hero">
    <div class="hero-body">
      <div class="container has-text-centered">
        <h1 class="title">
          {{ 'errors.403.title' | translate }}
        </h1>
      </div>
    </div>
  </section>

  <div class="container has-text-centered">
    <br/>
    <p>{{ 'errors.403.subtitle' | translate }}</p>
  </div>
</div>
