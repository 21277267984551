<app-element-table-row>
  <app-element-table-cell [isExpandButton]="true"
                          [expanded]="expanded"
                          (click)="toggleExpand.emit(line.position)"
                          class="expandable"
  ></app-element-table-cell>
  <app-element-table-cell [class]="{
    'is-warning': !matchesAvailabilities()
  }">
    <app-element-temporal-datetime [dateTime]="line.date" format="shortTime"></app-element-temporal-datetime>
  </app-element-table-cell>

  <ng-template [ngIf]="line.setupBlock" [ngIfElse]="notASetupBlock">
    <app-element-table-cell>
      <strong>{{'marathon.schedule.setupBlock' | translate}}</strong>
    </app-element-table-cell>
    <app-element-table-cell class="setup-text" columnEnd="span 5">
      <app-simple-md [data]="line.setupBlockText || 'marathon.schedule.setupBlock' | translate"></app-simple-md>
    </app-element-table-cell>
    <app-element-table-cell>
      <app-element-temporal-duration [duration]="line.setupTime"></app-element-temporal-duration>
    </app-element-table-cell>
  </ng-template>

  <ng-template #notASetupBlock>
    <app-element-table-cell>
        <p *ngFor="let runner of line.runners" [class]="{
          'has-text-warning': !isAvailable(runner)
        }">
          <a (click)="selectAvailability.emit({
              username: getRunnerUsername(runner),
              on: false
           })"
             *ngIf="selectedAvailabilities.includes(getRunnerUsername(runner)); else notSelected">
            <fa-icon [icon]="iconCalendarTimes"></fa-icon>
          </a>
          <ng-template #notSelected>
            <a (click)="selectAvailability.emit({
              username: getRunnerUsername(runner),
              on: true
           })">
              <fa-icon [icon]="iconCalendarWeek"></fa-icon>
            </a>
          </ng-template>
          {{ getRunnerDisplayName(runner) }}
        </p>
    </app-element-table-cell>
    <app-element-table-cell>
      <app-simple-md [data]="line.game"></app-simple-md>
    </app-element-table-cell>
    <app-element-table-cell>
      <app-simple-md [data]="line.category"></app-simple-md>
    </app-element-table-cell>
    <app-element-table-cell>
      {{ 'marathon.schedule.type.' + line.type | translate }}
    </app-element-table-cell>
    <app-element-table-cell>
      {{ line.console }}<sup *ngIf="line.emulated">{{ 'global.emu' | translate}}</sup>
    </app-element-table-cell>
    <app-element-table-cell>
      <app-element-temporal-duration [duration]="line.estimate"></app-element-temporal-duration>
    </app-element-table-cell>
    <app-element-table-cell>
      <app-element-temporal-duration [duration]="line.setupTime"></app-element-temporal-duration>
    </app-element-table-cell>
  </ng-template>

  <app-element-table-cell class="actions">
    <!-- Setup blocks are custom runs imo, but for legacy reasons they are not -->
    <a (click)="moveToToDo.emit(i)" *ngIf="!line.customRun && !line.setupBlock">
      <fa-icon [icon]="iconChevronLeft"></fa-icon>
    </a>
    <a (click)="delete.emit(i)" *ngIf="line.customRun || line.setupBlock">
      <fa-icon [icon]="iconTimes"></fa-icon>
    </a>
    <a (click)="toggleExpand.emit(line.position)">
      <fa-icon [icon]="iconEdit"></fa-icon>
    </a>
    <ng-content select="[oengusDragDandlePosition]"></ng-content>
  </app-element-table-cell>
</app-element-table-row>
