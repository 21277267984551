<section class="section container">
  <h1 class="title">{{'auth.passwordReset.submit' | translate}}</h1>

  <div class="notification" [class]="notificationClass" *ngIf="errorTranslationKey">
    <button class="delete" (click)="errorTranslationKey = null"></button>
    {{ errorTranslationKey | translate }}
  </div>

  <div class="columns">
    <div class="column is-three-fifths is-offset-one-fifth">
      <form>
        <fieldset [disabled]="loading && resetToken">

          <app-element-password-input [(password)]="newPassword"></app-element-password-input>

          <div class="control">
            <button type="submit" style="margin-right: 1rem"
                    [ngClass]="{'is-loading': loading}"
                    (click)="performReset()"
                    class="button is-primary">{{'auth.passwordReset.submit' | translate}}</button>
          </div>

        </fieldset>
      </form>
    </div>
  </div>
</section>
