<p class="menu-label">
  {{ 'marathon.menu.admin' | translate }}
</p>
<ul class="menu-list">
  <li [title]="'marathon.menu.settings' | translate">
    <a [routerLink]="'/marathon/' + marathonId + '/settings' | localize" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}" class="menu-item-link">
      <fa-icon [icon]="iconSettings" class="menu-item-icon"></fa-icon>
      <span class="menu-item-label">
        {{ 'marathon.menu.settings' | translate }}
      </span>
    </a>
  </li>
  <li [title]="'marathon.menu.selectRuns' | translate">
    <a [routerLink]="'/marathon/' + marathonId + '/selection' | localize" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}" class="menu-item-link">
      <fa-icon [icon]="iconSelectRuns" class="menu-item-icon"></fa-icon>
      <span class="menu-item-label">
        {{ 'marathon.menu.selectRuns' | translate }}
      </span>
    </a>
  </li>
  <li [title]="'marathon.menu.manageSchedule' | translate">
    <a [routerLink]="'/marathon/' + marathonId + '/schedule-management/overview' | localize" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}" class="menu-item-link">
      <fa-icon [icon]="iconManageSchedule" class="menu-item-icon"></fa-icon>
      <span class="menu-item-label">
        <span class="tag is-primary is-light">new!</span> {{ 'marathon.menu.manageSchedules' | translate }}
      </span>
    </a>
  </li>
  <li [title]="'marathon.menu.manageSchedule' | translate">
    <a [routerLink]="'/marathon/' + marathonId + '/schedule/manage' | localize" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}" class="menu-item-link">
      <fa-icon [icon]="iconManageSchedule" class="menu-item-icon"></fa-icon>
      <span class="menu-item-label">
        {{ 'marathon.menu.manageSchedule' | translate }}
      </span>
    </a>
  </li>
  <li *ngIf="marathon.hasDonations && marathon.hasIncentives" [title]="'marathon.menu.manageIncentives' | translate">
    <a [routerLink]="'/marathon/' + marathonId + '/incentives/manage' | localize" routerLinkActive="is-active" [routerLinkActiveOptions]="{exact: true}" class="menu-item-link">
      <fa-icon [icon]="iconManageIncentive" class="menu-item-icon"></fa-icon>
      <span class="menu-item-label">
        {{ 'marathon.menu.manageIncentives' | translate }}
      </span>
    </a>
  </li>
</ul>
