<div class="card {{gameStatus}}">
  <div class="card-content">
    <div class="media">
      <div class="media-content">
        <p class="title is-4 has-text-light">
          {{ game.name }} - {{ game.console }}<sup *ngIf="game.emulated">{{ 'global.emu' | translate }}</sup>
          <app-delete-button *ngIf="showDelete"
                             [baseText]="'marathon.submissions.game.delete' | translate"
                             [confirmText]="'action.confirm' | translate"
                             [cancelText]="'action.cancel' | translate"
                             (confirmHandler)="deleteGame.next()"
          ></app-delete-button>
        </p>
        <span *ngIf="userIsAdmin" class="subtitle is-6">{{ 'marathon.submissions.table.ratio' | translate }}: {{ game.ratio }}</span>
        <p class="subtitle is-6 has-text-light">
          <app-simple-md [data]="game.description"></app-simple-md>
        </p>

        <ng-template ngFor let-category [ngForOf]="game.categories">
          <app-submission-category
            [category]="category"
            [marathon]="marathon"
            [rawStatus]="getRawStatus(category)"
            [showDelete]="showDelete"
            (triggerDelete)="deleteCategory.next(category.id)"
          ></app-submission-category>
        </ng-template>
      </div>
    </div>
  </div>
</div>
